import styled from "styled-components"

export const MainHeader = styled.h1`
  color: #fefefe ${props => props.primary};
  font-size: 4rem;
  font-weight: 300;
  text-transform: uppercase;
  margin: 1rem 0;
  letter-spacing: 2px;
`
export const SecondaryHeader = styled.h2`
  color: var(--colorWhiteDark);
  letter-spacing: 1px;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 2rem;
`
export const GreenHeader = styled.h4`
  color: var(--colorPrimary);
  text-transform: uppercase;
  font-size: 2rem;
  margin-bottom: 2rem;
  letter-spacing: 1px;
`
export const TertiaryHeader = styled.h6`
  color: var(--colorWhiteDark);
  letter-spacing: 1px;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 2rem;
`
