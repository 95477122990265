import React from "react"
import { Helmet } from "react-helmet"
// import { useStaticQuery, graphql } from "gatsby"
// const query = graphql`
//   query {
//     site {
//       siteMetadata {
//         description
//         image
//       }
//     }
//   }
// `

const SEO = ({ title, description, keywords }) => {
  //   const { site } = useStaticQuery(query)
  return (
    <Helmet title={`${title} | Green Building Control`}>
      {/* <meta
        name="description"
        content={description || site.siteMetadata.description}
      />
      <meta name="image" content={site.siteMetadata.image} /> */}
      {keywords ? <meta name="keywords"></meta> : null}
      <meta charSet="utf-8" />
    </Helmet>
  )
}

export default SEO
