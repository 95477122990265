import styled, { keyframes } from "styled-components"
import React from "react"

const FadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);

  }

  to {
    opacity: 1;
    transform: translateY(0);


`
const StyledHeader = styled.header`
  min-height: 40rem;

  background: linear-gradient(
      to bottom right,
      rgba(126, 213, 111, 0.5),
      rgba(32, 145, 107, 0.5)
    ),
    linear-gradient(
      160deg,
      ${props => (props.home ? "rgba(32, 145, 107, 0.8) 15%," : null)}
        rgba(32, 145, 107, 0.8) 15%,
      rgba(40, 180, 133, 0.8) 70%,
      rgba(126, 213, 111, 0.8) 70%
    );
  background: linear-gradient(
      to bottom right,
      rgba(126, 213, 111, 0.5),
      rgba(32, 145, 107, 0.5)
    ),
    linear-gradient(
      160deg,
      ${props => (props.home ? "rgba(32, 145, 107, 0.8) 15%," : null)}
        rgba(32, 145, 107, 0.7) 15%,
      rgba(40, 180, 133, 0.7) 70%,
      rgba(126, 213, 111, 07) 70%
    );
  background-size: cover;
  background-position: center;
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 80%); */
  /* -webkit-box-shadow: inset 0px -30px 17px -13px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0px -30px 17px -13px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px -30px 17px -13px rgba(0, 0, 0, 0.2); */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9rem 2rem 0;
  position: relative;
  > div {
    max-width: 96rem;
    padding: 2rem 0;
    /* background: rgba(255, 255, 255, 0.1); */
    animation: ${FadeIn} 1s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (min-width: 900px) {
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    box-shadow: 0 4px 2rem rgba(0, 0, 0, 0.3);
  }
`

export default props => {
  return <StyledHeader home={props.home}>{props.children}</StyledHeader>
}
