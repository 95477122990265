import styled, { css } from "styled-components"

const greenStyling = css`
  background: linear-gradient(
    to top left,
    var(--colorSecondary),
    var(--colorPrimary)
  );

  color: var(--colorWhite);
`
const fadedGrayStyling = css`
  background: var(--colorWhiteDark);
`
export default styled.section`
  padding: 10rem 5rem;
  font-size: 1.6rem;
  > * {
    max-width: 96rem;
    width: 100%;
    margin: auto;
  }
  ${props =>
    props.green ? greenStyling : props.gray ? fadedGrayStyling : null}
`
