import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import links from "../../constants/links"
import { Navbar, Footer, TempLogo, NavItem } from "./styled"
import { TransitionLink } from "gatsby-plugin-transition-link/components/TransitionLink"
const GlobalStyling = createGlobalStyle`
    :root{
        --colorWhite: #f9f9f9;
        --colorWhiteDark: #F6F6F7;
        --colorPrimary: #19B382;
        --colorSecondary: #92DA84;
    }
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    body{
        background: #fff;
        font-family: "Montserrat", sans-serif;
    }
    html{
        font-size: 62.5%;
        @media screen and (max-width: 1200px){
          font-size: 56.25%;
        }
        @media screen and (max-width: 900px){
          font-size: 50%;
        }
        @media screen and (max-width: 450px){
          font-size: 45%;
        }
        @media screen and (min-width: var(1600px)){
          font-size: 75%;
        }
        color: #444;
    }
    a{
        text-decoration: none; 
        color: #555;
    }


`

//TRANSITIONCONTAINER
const MainContainer = styled.main`
  width: 100vw;
  transition: opacity 0.2s, transform 0.2s;
  opacity: ${props => (props.status === "entered" ? "1" : "0")};
  transform: translateY(${props => (props.status === "entered" ? "0" : "20%")});
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const Layout = props => {
  return (
    <FlexBox>
      <GlobalStyling></GlobalStyling>
      <Navbar>
        <div className="navContainer">
          <TransitionLink to="/" exit={{ length: 0.2 }} enter={{ length: 0.2 }}>
            <TempLogo home={props.page === "home"}>
              <span>Green</span> Building Control
            </TempLogo>
          </TransitionLink>
        </div>
      </Navbar>
      <MainContainer status={props.status}>
        <main>{props.children}</main>
      </MainContainer>
      <Footer status={props.status}>
        <div>
          <ul>
            <li className="header">Links</li>
            <li>
              <TransitionLink to="/" exit={{ length: 0.2 }}>
                home
              </TransitionLink>
            </li>
            {links.map(link => {
              return (
                <li key={link.title}>
                  <TransitionLink to={link.path} exit={{ length: 0.2 }}>
                    {link.title}
                  </TransitionLink>
                </li>
              )
            })}
          </ul>
          <div>
            <TransitionLink
              to="/"
              exit={{ length: 0.2 }}
              enter={{ length: 0.2 }}
            >
              <TempLogo home={true}>
                <span>Green</span> Building Control
              </TempLogo>
            </TransitionLink>
            <p>adres: Amsteldijk-Noord 140, 1422 XZ Uithoorn</p>
            <p>tel: +31639371094 of +31628711633</p>

            <p>
              email: bas@greenbuildingcontrol.nl of
              rutger@greenbuildingcontrol.nl
            </p>
          </div>
        </div>
        <div>
          <p>
            Deze website is gemaakt door{" "}
            <a href="https://danieldejong.com">Daniel de Jong</a>
          </p>
        </div>
      </Footer>
    </FlexBox>
  )
}
export default Layout
