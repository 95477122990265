import styled from "styled-components"
import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
export const Button = styled(TransitionLink)`
  color: var(--colorWhite);
  border: ${props =>
    props.type === "primary" ? "var(--colorWhite)" : "var(--colorSecondary)"};
  padding: 1rem 1.6rem;
  border: 3px solid
    ${props =>
      props.type === "primary" ? "var(--colorWhite)" : "var(--colorSecondary)"};
  display: inline-block;
  margin: 5rem 2rem 5rem 0rem;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.3s;
  outline: none;
  background: ${props =>
    props.type === "primary"
      ? "linear-gradient( 90deg,transparent 0%,transparent 50%,var(--colorWhite) 50%)"
      : "var(--colorSecondary)"};
  background-size: 220%;

  :hover {
    color: ${props =>
      props.type === "primary" ? "var(--colorSecondary)" : "var(--colorWhite)"};
    background-position: 100% center;
    transform: scale(1.05);
    span {
      transform: translateX(0.5rem);
      opacity: 1;
    }
  }
  :active {
    transform: scale(1.02);
  }
  span {
    transform: translateX(2rem);
    opacity: 0;
    transition: all 0.3s;
    display: inline-block;
  }
  @media screen and (max-width: 570px) {
    span {
      display: none;
    }
  }
`
export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.align ? props.align : "flex-start")};
`
export default props => {
  return (
    <Button
      exit={{ length: 0.2 }}
      enter={{ length: 0.2 }}
      to={props.to}
      type={props.type || "primary"}
    >
      {props.children}
      {props.arrow ? <span> &rarr;</span> : null}
    </Button>
  )
}
