import styled, { css } from "styled-components"
import Image from "gatsby-image"
import TransitionLink from "gatsby-plugin-transition-link"
// const scrolledStyling = css`
//   min-height: 6rem;
//   background: #f9f9f9;
// `

export const Sidebar = styled.div`
  width: 30rem;
  transform: translateX(${props => (props.openSide ? "0" : "-100%")});
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: var(--colorWhite);
  z-index: 3;
  transition: transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20rem;
  @media screen and (max-width: 570px) {
    width: 100%;
  }
  ul {
    display: flex;
    flex-direction: column;
    li {
      margin: 2rem 0;
      list-style: none;
      width: 100%;
      text-align: center;
    }
  }
  span {
    position: absolute;
    top: 5rem;
    right: 5rem;
    font-size: 4rem;
    cursor: pointer;
    width: 3rem;
    height: 3rem;

    ::after,
    ::before {
      content: "";
      width: 100%;
      height: 3px;
      background: #555;
      position: absolute;
      top: 50%;
    }
    ::after {
      transform: rotate(135deg);
    }
    ::before {
      transform: rotate(45deg);
    }
  }
`
export const NavItem = styled(TransitionLink)`
  color: var(--colorWhite);
  position: relative;
  font-size: 1.9rem;
  text-transform: lowercase;
  padding: 1rem 0;

  ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    width: 0;
    display: block;
    background: var(--colorSecondary);
    transition: width 0.3s;
  }
  :hover {
    ::after {
      width: 100%;
    }
  }
`

export const Navbar = styled.nav`
  min-height: 10rem;
  transition: all 0.4s;
  /* background: rgba(255,255,255,0.1); */
  /* :hover{
    background: #f9f9f9;
  } */
  position: absolute;
  z-index: 2;
  width: 100%;
  /* padding: 2rem 5rem; */
  display: flex;
  justify-content: flex-start;
  /* box-shadow: 0 1px 1.5rem rgba(0, 0, 0, 0.2); */
  /* :after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
    transform: translateY(${props => (props.scrolling ? "0" : "-100%")});
    background: #f9f9f9ad;
    z-index: 0;
  } */
  > div.navContainer {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 96rem;
    margin: auto;
    width: 80%;
    margin-bottom: 20px;
    ul{
      display: flex;

      li{
        list-style: none;
        margin-right: 2rem;
      }
      @media screen and (max-width: 720px){
        display: none;
      }
    }
  }
`

export const Logo = styled(Image)`
  width: 8rem;
  z-index: 1;
`
export const TempLogo = styled.h2`
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 300;
  color: ${props => (!props.home ? "#444" : "var(--colorWhite)")};

  > span {
    color: var(--colorSecondary);
  }
`
export const HamburgerMate = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 7rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  left: 5%;
  > div {
    width: 3rem;
    height: 3px;
    background: ${props => (!props.scrolling ? "#444" : "var(--colorWhite)")};
    position: relative;
    transition: all 0.3s;
    ::before {
      content: "";
      width: 3rem;
      height: 3px;
      background: ${props => (props.scrolling ? "#444" : "var(--colorWhite)")};
      position: absolute;
      bottom: 1rem;
      transition: all 0.1s;
    }
    ::after {
      content: "";
      width: 3rem;
      height: 3px;
      background: ${props => (props.scrolling ? "#444" : "var(--colorWhite)")};
      position: absolute;
      top: 1rem;
      transition: all 0.1s;
    }
  }

  :hover {
    > div {
      ::after {
        top: 1.12rem;
      }
      ::before {
        bottom: 1.12rem;
      }
    }
  }
`
export const Footer = styled.footer`
  background: #555;
  transition: opacity 0.3s;
  opacity: ${props => (props.status === "entered" ? 1 : 0)};
  width: 100%;
  padding: 3rem 0;
  margin-top: auto;
  position: relative;
  .reference_to_daniel {
    margin-top: 20px;
  }
  p,
  a {
    color: #999;
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }
  > div {
    max-width: 96rem;
    margin: auto;
    padding: 5rem 3rem;
    display: flex;
    justify-content: space-around;
    width: 100%;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
    }
    div {
      h2 {
        margin-bottom: 1rem;
      }

      border-bottom: 1px solid #666;
    }
    ul {
      list-style: none;
      margin-top: 20px;
      li {
        margin: 0 0 1rem 0;
        a {
          color: #999;
          font-size: 1.6rem;
          transition: color 0.3s;
          :hover {
            color: #c9c9c9;
          }
        }
      }
      li.header {
        font-size: 2rem;
        color: #bbb;
      }
    }
  }
`
